import React from 'react'
import Layout from '../components/layout'

function impressum() {
    return (
        <Layout>
            <div className="main">
                <div className="global-text-container">
                    <h1>Impressum</h1>
                    <p><strong>Inhaber<br /></strong>by Olga Ribler GmbH<br />Weggisgasse 1<br />6004
                        Luzern<br />Tel.:&nbsp;<a href="tel:+41413612390">+41 41 361 23 90</a><br />Fax.: +41 41 361 23
                        91<br />MwSt.-Nr.: 446141</p>
                    <p><strong>Gesch&auml;ftsf&uuml;hrerin</strong><br />Olga Ribler</p>
                    <p>Wir sichern Dir Vertraulichkeit im Umgang mit Deinen Angaben zu &ndash; jede uns zug&auml;nglich
                        gemachte Information wird ausschliesslich f&uuml;r den jeweils beschriebenen Zweck
                        verwendet.<br />Die Inhalte dieser Website, insbesondere Bilder, Grafiken, Tondokumente,
                        Videosequenzen, Texte, usw., sind urheberrechtlich gesch&uuml;tzt. Der Urheberrechtsschutz ist
                        gew&auml;hrleistet durch das Schweizer Bundesgesetz &uuml;ber das Urheberrecht und verwandte
                        Schutzrechte (SR 231.1) sowie das Bundesgesetz gegen den unlauteren Wettbewerb (SR 241). Die
                        Inhalte dieser Website sind ausschliesslich f&uuml;r den privaten Gebrauch bestimmt. Jegliche
                        andere Nutzung, in welcher Form auch immer, ist untersagt, insbesondere die Publikation oder die
                        Vervielf&auml;ltigung der Inhalte dieser Website sowie jede Nachahmung davon.</p>
                    <p><strong>Web Design</strong></p>
                    <a href="https://www.prepublic.org">PREPUBLIC.org</a>
                </div>
            </div>
        </Layout>
    )
}

export default impressum
